/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns/dist/npm/main';
import { Tooltip } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs/dist/npm/main';
import { DebounceInput } from 'react-debounce-input';
import { roundNumber } from '../../integration/CommonUtils';

class CustomerDetails extends Component {
    popupSet = {
        width: 'auto'
    };

    constructor(props) {
        super(props);
        this.state = {
            isViewMode: false,
            cusToolTipOpen: false,
            programToolTipOpen: false,
            opidToolTipOpen: false,
            projidToolTipOpen: false,
            UUIDName: 'Project ID',
            editable: true,
            isOnHold: false,
            decimalPlaces: 0,
            contractedEffortToolTipOpen: false,
            poNumberToolTipOpen: false
        }
    }

    componentDidMount() {
        if (this.props.UUIDName) {
            this.setState({
                UUIDName: this.props.UUIDName
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.action && this.props.action !== prevProps.action) {
            this.setState({
                isViewMode: this.props.action === 'view' ? true : false,
                editable: this.props.editable,
                isOnHold: this.props.isStatusOnHold
            });

        }

        if (this.props !== prevProps) {
            this.setState({
                editable: this.props.editable,
                isOnHold: this.props.isStatusOnHold
            });

            if (this.state.isOnHold) {
                this.props.permissions.customer = false;
                this.props.permissions.program = false;
                this.props.permissions.OPID = false;
            }


        }
    }

    handleChange = (event) => {
        this.props.handleOnChange(event);
    };

    handleFilterChange = event => {
        this.props.handleFilterChange(event);
    };

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };

    toggleCustomerTooltip = () => {
        this.setState({
            cusToolTipOpen: !this.state.cusToolTipOpen
        });
    };

    toggleProgramTooltip = () => {
        this.setState({
            programToolTipOpen: !this.state.programToolTipOpen
        });
    };

    onClickOpidHistory = () => {
        this.props.onClickOpidHistory();
    };

    toggleOpidTooltip = () => {
        this.setState({
            opidToolTipOpen: !this.state.opidToolTipOpen
        });
    };

    togglePoNumberTooltip = () => {
        this.setState({
            poNumberToolTipOpen: !this.state.poNumberToolTipOpen
        });
    };

    toggleContractedEffortTooltip = () => {
        this.setState({
            contractedEffortToolTipOpen: !this.state.contractedEffortToolTipOpen
        });
    };

    toggleProjectidTooltip = () => {
        this.setState({
            projidToolTipOpen: !this.state.projidToolTipOpen
        });
    };

    render() {

        return (
            <div>
                <div className="main-heading">Customer Details</div>
                <div className="row">
                    <div className="col-md-2">
                        <div className="d-block">
                            <label htmlFor="" className="mandatory">
                                Customer:
                            </label>
                        </div>
                        <div className="d-block">
                            <div className="" id="cusnameToolTip">
                                <ComboBox
                                    data={this.props.customerItems}
                                    textField="Name"
                                    dataItemKey="id"
                                    placeholder="Please Select"
                                    name="customer"
                                    value={this.props.customerValue}
                                    onChange={this.handleChange}
                                    required={true}
                                    disabled={this.state.isViewMode || !this.props.permissions.customer || this.state.editable === false}
                                    filterable={true}
                                    onFilterChange={this.handleFilterChange}
                                />
                                {this.props.errorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.props.customerValue)}>
                                        Please select a customer
                                </span>
                                ) : null}
                            </div>

                            {this.props.customerValue ? (
                                <Tooltip
                                    placement="top"
                                    isOpen={this.state.cusToolTipOpen}
                                    target="cusnameToolTip"
                                    toggle={this.toggleCustomerTooltip}
                                >
                                    {this.props.customerValue.Name}
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                        <div className="d-block">
                            <label htmlFor="">Program:</label>
                        </div>
                        <div className="d-block">
                            <div className="" id="prognameToolTip">
                                <ComboBox
                                    data={this.props.programItems}
                                    textField="Name"
                                    placeholder="Please Select"
                                    value={this.props.programValue}
                                    name="program"
                                    onChange={this.handleChange}
                                    dataItemKey="id"
                                    disabled={this.state.isViewMode || !this.props.permissions.program}
                                    filterable={true}
                                    onFilterChange={this.handleFilterChange}
                                />
                            </div>
                            {this.props.programValue ? (
                                <Tooltip
                                    placement="top"
                                    isOpen={this.state.programToolTipOpen}
                                    target="prognameToolTip"
                                    toggle={this.toggleProgramTooltip}
                                >
                                    {this.props.programValue.Name}
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                        <div className="d-block">
                            <label htmlFor="" className="mandatory">
                                OP ID:
                        </label>
                        </div>
                        <div className="d-block">
                            <div className="" id="opidToolTip">
                                <Input
                                    autoComplete="off"
                                    type="text"
                                    maxLength="6"
                                    name="opid"
                                    value={this.props.opidValue}
                                    onChange={this.handleChange}
                                    required={true}
                                    disabled={this.state.isViewMode || !this.props.permissions.OPID || !this.props.allowOpidEdit}
                                    validationMessage=""
                                />
                                {this.props.errorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.props.opidValue)}>Please enter an OP ID</span>
                                ) : null}
                            </div>
                            {this.props.opidValue ? (
                                <Tooltip
                                    placement="top"
                                    isOpen={this.state.opidToolTipOpen}
                                    target="opidToolTip"
                                    toggle={this.toggleOpidTooltip}
                                >
                                    {this.props.opidValue}
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                        <div className="d-block">
                            <label htmlFor="">
                                PO Number:
                            </label>
                        </div>
                        <div className="d-block">
                            <div className="" id="poNumberToolTip">
                                <Input
                                    autoComplete="off"
                                    type="text"
                                    name="poNumber"
                                    value={this.props.poNumber}
                                    onChange={this.handleChange}
                                    disabled={this.state.isViewMode}
                                />
                            </div>
                            {this.props.poNumber ? (
                                <Tooltip
                                    placement="top"
                                    isOpen={this.state.poNumberToolTipOpen}
                                    target="poNumberToolTip"
                                    toggle={this.togglePoNumberTooltip}
                                >
                                    {this.props.poNumber}
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-1 d-flex align-items-end pb-1 ps-0" style={{marginLeft: "-15px", marginRight: "15px"}}>
                        {this.props.oldOpIdHistory && this.props.oldOpIdHistory.length > 0 && (
                            <a  onClick={this.onClickOpidHistory}>
                                <div className="rounded-link">
                                    <span className="k-icon k-i-clock" title="OPID Change History" />
                                </div>
                            </a>
                        )}
                    </div>

                    {this.props.billingDivisionValue && this.props.billingDivisionValue.id === 30 ? (
                        <>
                        <div className="col-md-2">
                            <div className="d-block">
                                <label htmlFor="">
                                    Monthly Contractual Hours:
                                </label>
                            </div>
                            <div className="d-block">
                                <div className="" id="contractedEffortToolTip">
                                <DebounceInput
                                    width="100%"
                                    value={roundNumber(this.props.contractedEffort,2)}
                                    disabled={this.state.isViewMode}
                                    type="number"
                                    step={this.state.decimalPlaces}
                                    pattern="[0-9]*"
                                    min="0" 
                                    className="k-textbox"
                                    name="contractedEffort"
                                    onChange={this.handleChange}
                                />  
                                </div>
                                {this.props.contractedEffort ? (
                                    <Tooltip
                                        placement="top"
                                        isOpen={this.state.contractedEffortToolTipOpen}
                                        target="contractedEffortToolTip"
                                        toggle={this.toggleContractedEffortTooltip}
                                    >
                                        {this.props.contractedEffort}
                                    </Tooltip>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        </>
                    ) : null}
                
                {this.props.action === 'edit' || this.props.action === 'view' ? (
                        <div className="col-md-2">
                                <div className="d-block">
                                    <label>{this.state.UUIDName}</label>
                                </div>
                                <div className="d-block" id="projidToolTip">
                                    <Input
                                        autoComplete="off"
                                        type="text"
                                        name="projectid"
                                        value={this.props.projectIdValue}
                                        onChange={this.handleProjectidChange}
                                        disabled={true}
                                    />
                                
                                {this.props.projectIdValue ? (
                                    <Tooltip
                                        placement="top"
                                        isOpen={this.state.projidToolTipOpen}
                                        target="projidToolTip"
                                        toggle={this.toggleProjectidTooltip}
                                    >
                                        {this.props.projectIdValue}
                                    </Tooltip>
                                ) : null}
                                </div>
                            </div>
                ) : null}
                </div>
            </div>

        );
    }
}

export default CustomerDetails;
