import React from 'react';
import { Redirect } from 'react-router';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
    getChangeRequest,
    updateChangeReq,
    saveChangeReq
} from './ChangeRequestService';
import { getSalesPersonIntegrated, getProjectPartnersIntegrated, getRegionsWithCountriesIntegrated, getBillingDiviIntegrated, getProjectTypesIntegrated, getPaymentMethodsIntegrated, getcustomersIntegrated, getOfferingsIntegrated, getCustomerProgramsIntegrated, getPracticeTypesIntegrated, getAllPracticeDivisionMapIntegrated } from '../../integration/MasterDataService';
import { getPMResourcesIntegrated } from '../../integration/ResourceManagementService';
import CustomerDetails from '../components/CustomerDetails';
import EditCRDetails from './EditCRDetails';
import { Button } from '@progress/kendo-react-buttons';
import BillingDetails from '../components/BillingDetails';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { UncontrolledTooltip } from 'reactstrap';
import ResourceRequest from '../components/ResourceRequest';
import { filterBy } from '@progress/kendo-data-query';
import FormValidator from '../../integration/components/FormValidator';
import { getProject, saveMilestones } from '../../projectManagement/project/ProjectService';
import InfoDialog from '../../integration/components/InfoDialog';
import ConfirmationDialog from '../../integration/components/ConfirmationDialog';
import Loader from '../../integration/components/Loader';
import { saveAuditTrail } from "../../integration/auditTrail/AuditTrailService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import * as loggerService from "../../integration/LoggerService";
import { getFormattedDate, reStructureDate } from "../../integration/CommonUtils";
import moment from "moment";
import { saveResourceRequests, savePresalesResourceLineHistory, getLegalEntityTypes } from '../../resourceManagement/ResourceService';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import Milestones from '../components/Milestones';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

const customerAll = [];
const resourceAll = [];

const Dialogs = Object.freeze({
    CONFIRMATION: 'confirmation',
    INFO: 'info'
});

class EditChangeRequest extends React.Component {
    default;

    preDefinedPermissions = {
        customer: { preSales: true, billable: false, other: false },
        program: { preSales: true, billable: false, other: false },
        OPID: { preSales: true, billable: true, other: false },
        country: { preSales: false, billable: false, other: false },
        CRName: { preSales: true, billable: true, other: false },
        startDate: { preSales: true, billable: false, other: false },
        status: { preSales: true, billable: true, other: true },
        description: { preSales: true, billable: true, other: false },
        billableStatus: { preSales: true, billable: false, other: false },
        salesPerson: { preSales: false, billable: false, other: false },
        projectPartner: { preSales: false, billable: false, other: false },
        practice: { preSales: true, billable: false, other: false },
        billingRegion: { preSales: false, billable: false, other: false },
        engagementType: { preSales: true, billable: true, other: false },
        billingMethod: { preSales: false, billable: false, other: false },
        offering: { preSales: true, billable: true, other: false },
        endDate: { preSales: true, billable: true, other: false },
        endDateAugmentation: { preSales: true, billable: true, other: false },
        endDateBlanketWork: { preSales: true, billable: true, other: false },
    };

    constructor(props) {
        super(props);

        // TODO : Move validations to the relevant component and refactor.
        const defaultValidation = {
            method: 'isEmpty',
            validWhen: false,
            message: 'Please fill the field.'
        };

        this.validator = new FormValidator([
            Object.assign({ field: 'cusnameValue' }, defaultValidation),
            Object.assign({ field: 'opid' }, defaultValidation),
            Object.assign({ field: 'countryValue' }, defaultValidation),
            Object.assign({ field: 'billingdiviValue' }, defaultValidation),
            Object.assign({ field: 'billingregionValue' }, defaultValidation),
            //Object.assign({ field: 'resourceValue' }, defaultValidation),
            {
                field: 'projtypeValue',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please select an engagement type.'
            },
            {
                field: 'paymethodValue',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please select a billing method.'
            },
            {
                field: 'offeringValue',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please select an offering.'
            }
        ]);

        this.state = {
            changeRequestId: null,
            action: '',
            projectId: null,
            offering: false,
            billablestatus: true,
            //resourceItems: resourceAll.slice(),
            paymentmethodItems: [],
            billingdiviItems: [],
            billingregionItems: [],
            projectTypeItems: [],
            projparnterItems: [],
            salespersonItems: [],
            opid: '',
            onHoldStatusBool: false,
            billingMethod: false,
            programItems: [],
            customerItems: customerAll.slice(),
            countryItems: [],
            offeringItems: [],

            statusValue: null,
            cusnameValue: null,
            prognameValue: null,
            salespersValue: null,
            projpartnerValue: null,
            billingdiviValue: null,
            billingregionValue: null,
            projtypeValue: null,
            paymethodValue: null,
            //resourceValue: null,
            countryValue: null,
            offeringValue: null,
            toDashboard: false,
            visible: false,
            billale: { value: null, touched: false },
            success: false,
            dialogMessage: '',
            dialogTitle: '',
            isEnableCreate: false,
            isEnableUpdate: false,
            isDisableSave: false,
            changeReqId: '',
            isFormDirty: false,
            showCancelConfirmation: false,
            defaultStatus: null,
            allSalesPersons: [],
            allProjectPartners: [],
            allBillingRegions: [],
            isErrorMessageVisible: false,
            prevStatus: null,

            showConfirmDialog: false,
            confirmDialogMessage: '',
            confirmDialogAction: null,

            isViewMode: false,
            permissions: {
                customer: true,
                program: true,
                OPID: true,
                country: true,
                CRName: true,
                startDate: true,
                status: true,
                description: true,
                billableStatus: true,
                salesPerson: true,
                projectPartner: true,
                practice: true,
                billingRegion: true,
                engagementType: true,
                billingMethod: true,
                offering: true,
            },

            changeReqDetails: null,
            changeReqDetailsValid: true,
            showStartDateValidationError: false,
            showEndDateValidationError: false,
            submitted: false,
            validation: this.validator.valid(),
            dialog: {},
            redirect: '',
            editBillingDetails: true,
            isLoading: false,
            triggerRedirect: '',
            isAps: false,
            endDate: null,
            startDate: null,
            changeRequestConversionStartDate: null,
            allocComment : null,

            preSalesResourceAllocations : [],
            //for resource augmentation
            endDateAugmentation:null,
            resourceAug:false,

            //for blanket work order
            endDateBlanketWork:null,
            isBlanketWorkOrder:false,
            showMessageDialog: false,
            showNbWarning: false,
            practiceItems: [],
            practiceValue: null,
            practiceDivisionMap: [],
            oldOpIdHistory: [],
            allowOpidEdit: true,
            visibleOpidHistory: false,
            poNumber: '',
            contractedEffort: '',
            selected: 0,
            milestones: [],
            showSuccessImage: false,
            showErrorImage: false,
            billingEntityType: [],
            billingEntityValue: null,      
        };
    }

    handleInputChange = async event => {
        const value = event.target.value;
        const field = event.target.name;

        switch (field) {
            case 'customer': {
                this.setState({
                    cusnameValue: value,
                    programItems: [],
                    prognameValue: null
                });
                if (value) {
                    this.populatePrograms(value.id);
                } else {
                    this.setState({
                        programItems: [],
                        prognameValue: null
                    });
                }
                break;
            }
            case 'program': {
                this.setState({
                    prognameValue: value
                });
                break;
            }
            case 'contractedEffort': {
                if (value >= 0) {
                    this.setState({
                        contractedEffort: value,
                    });
                } else {
                    this.setState({
                        contractedEffort: '',
                    });
                }
                break;
            }        
            default: {
                await this.setState({ [field]: value, isFormDirty: true });
            }
        }
        this.setState({
            isFormDirty: true
        });
    };

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.setHeaderTitle(this.props.location.action);
        this.setInitialData();
    }

    onSaveAuditTrail = (ChangeReq, changeReqId) => {
        const auditTrail = {
            Action: 'Change Request viewed',
            RefType: 'Change Request',
            RefId: changeReqId,
            RefName: ChangeReq.UniqueId
        }
        saveAuditTrail(auditTrail)
    }


    toggleConfirmDialog = async (message, action) => {
        await this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action
        });
    };

    toggleWarningDialog = (message, title) => {
        this.setState({
          showMessageDialog: !this.state.showMessageDialog,
          dialogMessage: message,
          dialogTitle: title
        });
      };

    showDialog = (message, title, type, callback) => {
        const dialog = {
            infoDialogVisible: type === Dialogs.INFO,
            confirmationDialogVisible: type === Dialogs.CONFIRMATION,
            dialogMessage: message,
            dialogTitle: title,
            callback: callback
        };
        this.setState({
            dialog: dialog
        });
    };

    setInitialData = async () => {
        this.setState(
            {
                action: this.props.location.action,
                isViewMode: this.props.location.action === 'view' ? true : false
            },
            async () => {
                if (
                    (this.props.location.action === 'view' || this.props.location.action === 'edit') &&
                    this.props.location.changeReqId > 0
                ) {
                    let test = false;
                    await getChangeRequest(this.props.location.changeReqId)
                        .then(res => {
                            if (res && res.data && res.data.id === this.props.location.changeReqId) {
                                if (this.props.location.action === 'view') {
                                    this.renderCRDetails(res.data);
                                    this.onSaveAuditTrail(res.data, this.props.location.changeReqId)
                                } else if (this.props.location.action === 'edit') {
                                    this.renderCRDetails(res.data);
                                    this.state.billingMethod = true;
                                    this.state.offering = true;

                                    test = res.data.Status.StatusName === 'On Hold' ? true : false;


                                }
                                this.setPermissionsOfFields();
                                this.populateDropDownData();
                                this.setState({
                                    preSalesResourceAllocations: res.data.ResourceRequest,
                                    milestones: res.data.Milestone
                                });
                            }
                            this.setState({
                                editBillingDetails: this.props.location.action === 'view' ? false : true,
                                onHoldStatusBool: test

                            });

                        })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });
                } else {
                    this.populateDropDownData();
                    await getProject(this.props.location.projectId).then(async response => {
                        await this.renderCRDetails(this.createCRFromProject(response.data));
                        await this.practiceDivisionMapping();
                    })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });
                    this.setState({
                        changeRequest: {},
                        projectId: this.props.location.projectId,
                        editBillingDetails: true
                    });
                }
            }
        );
    };

    checkOPIdHistory = async() => {
        if (this.state.changeRequest && this.state.changeRequest.OpidHistory && this.state.changeRequest.OpidHistory.length > 0) {
            let oldOpIdHistory = [];
            const sortedHistory = this.state.changeRequest.OpidHistory.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            for (const item of sortedHistory) {
                let isInitOpid = sortedHistory.indexOf(item) == 0 ? true : false;
                const obj = {
                    OPID: item.OPID,
                    UpdatedUser: item.UpdatedUser,
                    createdAt: isInitOpid ? "" : moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss')
                }
                oldOpIdHistory.push(obj);
            }
            if(this.state.changeRequest.OpidHistory.length >= 3) {
                await this.setState({
                    oldOpIdHistory: oldOpIdHistory,
                    allowOpidEdit: false
                });
            } else {
                await this.setState({
                    oldOpIdHistory: oldOpIdHistory,
                    allowOpidEdit: true
                });  
            }
        } else {
            await this.setState({
                oldOpIdHistory: this.state.changeRequest.OpidHistory,
                allowOpidEdit: true
            });
        }
    }

    onClickOpidHistory = async() => {
        this.setState({
            visibleOpidHistory: !this.state.visibleOpidHistory
        });
    }

    setPermissionsOfFields = () => {
        if (this.props.location.action === 'view') {
            this.setState({
                isViewMode: true
            });
        } else if (this.props.location.action === 'edit') {

            this.state.permissions.practice = false;
            this.state.permissions.engagementType = false;


            const cmSheets = this.state.changeRequest.CMSheets;
            if (cmSheets.length > 0) {
                const permissions = Object.assign({}, this.state.permissions);
                if (
                    this.state.changeRequest.Status &&
                    (this.state.changeRequest.Status.Code === 'BILLABLE' ||
                        this.state.changeRequest.Status.Code === 'BILLABALE_CR' ||
                        this.state.changeRequest.Status.Code === 'WORK_AT_RISK')
                ) {
                    for (const field of Object.keys(permissions)) {
                        permissions[field] = this.preDefinedPermissions[field].billable;
                    }
                } else if (
                    this.state.changeRequest.Status &&
                    this.state.changeRequest.Status.Code === 'PRESALES'
                ) {
                    for (const field of Object.keys(permissions)) {
                        permissions[field] = this.preDefinedPermissions[field].preSales;
                    }
                } else {
                    for (const field of Object.keys(permissions)) {
                        permissions[field] = this.preDefinedPermissions[field].other;
                    }
                }

                if(this.state.changeRequest.Status){
                    if(this.state.changeRequest.Status.Code === 'PRESALES' || this.state.changeRequest.Status.Code === 'WAIT_CONFIRM' || this.state.changeRequest.Status.Code === 'ON_HOLD' || this.state.changeRequest.Status.Code === 'WORK_AT_RISK' ){
                        permissions['startDate'] = true;
                    }
                }

                this.setState({
                    permissions: permissions
                });
            }
        }
    };

    createStateObject = source => {
        if (source) {
            return Object.assign({}, source);
        } else {
            return null;
        }
    };

    // Creates initial change request data from project.
    createCRFromProject = project => {
        let changeRequest = {};
        changeRequest.Customer = project.Customer;
        changeRequest.Program = project.Program;
        changeRequest.BillableStatus = project.BillableStatus;
        changeRequest.BillingDivision = project.Division;
        changeRequest.Region = project.Region;
        changeRequest.Country = project.Country;
        changeRequest.Type = project.Type;
        changeRequest.PaymentMethod = project.PaymentMethodRecord;
        changeRequest.SalesPerson = project.SalesPersonRecord;
        changeRequest.Partner = project.PartnerRecord;
        changeRequest.ProjectId = project.id;
        changeRequest.CRPractice = project.ProjectPractice;
        return changeRequest;
    };

    // Renders change request data returned from API
    renderCRDetails = async changeRequest => {
        if (changeRequest.BillingDivision.id === 30 && changeRequest.Type.Name === "Application Support") {
            this.state.permissions.endDate = true;
        }
        else if(changeRequest.Type.Name === "Resource Augmentation"){
            this.state.permissions.endDateAugmentation = true;
        }
        else if(changeRequest.Type.Name === "Blanket Work Order"){
            this.state.permissions.endDateBlanketWork = true;
        }
        else {
            this.state.permissions.endDate = false;
            this.state.permissions.endDateAugmentation = false;
            this.state.permissions.endDateBlanketWork = false;
        }
        if (changeRequest) {
            let editable = true;
            if (changeRequest.CMSheets && changeRequest.CMSheets.length > 0) {
                editable = false;
            }

            if (changeRequest.Region) {
                this.populateCountries(changeRequest.Region.Country);
                this.countryAll = changeRequest.Region.Country;
            }
            const resourceObj = this.createStateObject(changeRequest.PresalesResource);
            const customerObj = this.createStateObject(changeRequest.Customer);
            const programObj = this.createStateObject(changeRequest.Program);
            const salespersonObj = this.createStateObject(changeRequest.SalesPerson);
            const projpartnerObj = this.createStateObject(changeRequest.Partner);
            const billingDiviObj = this.createStateObject(changeRequest.BillingDivision);
            const billingRegionObj = this.createStateObject(changeRequest.Region);
            const projectTypeObj = this.createStateObject(changeRequest.Type);
            const paymentmethodObj = this.createStateObject(changeRequest.PaymentMethod);
            const countryObj = this.createStateObject(changeRequest.Country);
            const offeringObj = this.createStateObject(changeRequest.Offering);
            const statusObj = this.createStateObject(changeRequest.Status);
            const practiceObj = this.createStateObject(changeRequest.CRPractice);
            const billingEntityObj = this.createStateObject(changeRequest.LegalEntity);

            await this.setState({
                editable: editable,
                startDate: changeRequest.StartDate ? reStructureDate(changeRequest.StartDate) : null,
                billablestatus: changeRequest.BillableStatus,
                cusnameValue: customerObj,
                prognameValue: programObj,
                salespersValue: salespersonObj,
                projpartnerValue: projpartnerObj,
                billingdiviValue: billingDiviObj,
                billingregionValue: billingRegionObj,
                projtypeValue: projectTypeObj,
                paymethodValue: paymentmethodObj,
                resourceValue: resourceObj,
                opid: changeRequest.OPID,
                changeReqId: changeRequest.UniqueId,
                countryValue: countryObj,
                offeringValue: offeringObj,
                changeReqDetails: null,
                changeRequest: changeRequest,
                changeRequestId: changeRequest.id,
                projectId: changeRequest.ProjectId,
                statusValue: statusObj,
                practiceValue: practiceObj,
                poNumber: changeRequest.PONumber,
                contractedEffort: changeRequest.ContractedEffort ? Number(changeRequest.ContractedEffort).toFixed(2) : '',
                billingEntityValue: billingEntityObj
            });

            if(this.state.permissions.endDateAugmentation === true){

                this.setState({
                    endDateAugmentation: changeRequest.EndDate ? reStructureDate(changeRequest.EndDate) : null,
                })
            }
            else if(this.state.permissions.endDateBlanketWork === true){
                this.setState({
                    endDateBlanketWork: changeRequest.EndDate ? reStructureDate(changeRequest.EndDate) : null,
                })
            }
            else if(this.state.permissions.endDate === true){
                this.setState({
                    endDate: changeRequest.EndDate ? reStructureDate(changeRequest.EndDate) : null,
                })
            }

            this.checkOPIdHistory();


        } else {
            return null;
        }
    };

    setHeaderTitle = async action => {
        if (this.props.onHeaderTitleChange) {
            if (action === 'view') {
                this.props.onHeaderTitleChange('View Change Request');
            } else if (action === 'edit') {
                this.props.onHeaderTitleChange('Edit Change Request');
            } else {
                this.props.onHeaderTitleChange('Create a Change request');
            }
        }
    };

    populateCountries = countries => {
        this.setState({
            countryItems: countries
        });
    };

    populatePrograms = customerId => {
        if (customerId) {
            getCustomerProgramsIntegrated(customerId)
                .then(res => {
                    this.allPrograms = res.data;
                    if (res) {
                        this.setState({
                            programItems: res.data
                        });
                    }
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
        } else {
            this.setState({
                programItems: []
            });
        }
    };

    handleFilterChange = event => {
        const field = event.target.name;

        switch (field) {
            case 'customer': {
                this.setState({
                    customerItems: this.filterData(event.filter)
                });
                break;
            }
            case 'program': {
                this.setState({
                    programItems: this.filterProgramData(event.filter)
                });
                break;
            }
            default: {
            }
        }

        this.setState({
            isFormDirty: true
        });
    };

    populateDropDownData = () => {
        getPMResourcesIntegrated().then(response => {
            this.resourceAll = response.data;
            this.setState({
                resourceItems: response.data
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        getProjectPartnersIntegrated().then(response => {
            this.allProjectPartners = response.data;
            this.setState({
                projparnterItems: response.data
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        getBillingDiviIntegrated().then(response => {
            this.allBillingDivisions = response.data.filter(obj => obj.IsActive == true && obj.SFCode !== null);
            this.setState({
                billingdiviItems: response.data.filter(obj => obj.IsActive == true && obj.SFCode !== null)
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        getPracticeTypesIntegrated().then(response => {
            this.allPracticeTypes = response.data;
            this.setState({
                practiceItems: response.data
            });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    
        getAllPracticeDivisionMapIntegrated().then(response => {
            this.setState({
                practiceDivisionMap: response.data
            });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

        getProjectTypesIntegrated().then(response => {
            this.allEngagementTypes = response.data;
            this.setState({
                projectTypeItems: response.data
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        getSalesPersonIntegrated().then(response => {
            this.allSalesPersons = response.data;
            this.setState({
                salespersonItems: response.data
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        getRegionsWithCountriesIntegrated().then(response => {
            this.allBillingRegions = response.data;
            this.setState({
                billingregionItems: response.data
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        getPaymentMethodsIntegrated().then(response => {
            this.allBillingMethods = response.data;
            this.setState({
                paymentmethodItems: response.data
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        getcustomersIntegrated().then(response => {
            this.customerAll = response.data;
            this.setState({
                customerItems: response.data
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        getOfferingsIntegrated().then(response => {
            this.allOfferings = response.data;
            this.setState({
                offeringItems: response.data
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        getLegalEntityTypes().then(response => {
            this.allBillingEntities = response.data;
            this.setState({
                billingEntityType: response.data
            });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

    onCRDetailsChange = callbackData => {
        this.setState({
            changeReqDetails: callbackData.changeReqDetails,
            changeReqDetailsValid: callbackData.isValid,
            statusValue: callbackData.statusValue,
            isFormDirty: true
        });
        if (this.state.changeReqDetails && this.state.changeReqDetails.StartDate) {
            this.setState({
                showStartDateValidationError: false
            });
        }
    };

    onHandleStartDate = (callbackData) => {
        this.setState({
            startDate: reStructureDate(callbackData),
        });

        if (callbackData) {
            if(!this.isResourceAug() || !this.isBlanketWork()){
                let endDate = null;

                endDate = reStructureDate(callbackData.valueOf())
                endDate.setFullYear(endDate.getFullYear() + 1);
                endDate.setDate(endDate.getDate() - 1);
                this.setState({
                    endDate: endDate
                })
            }

        }
    };

    onHandleEndDate = (callbackData) => {
        if(this.isResourceAug()){
            this.setState({
                endDateAugmentation: callbackData,
            });
        }
        else if(this.isBlanketWork()){
            this.setState({
                endDateBlanketWork: callbackData,
            });
        }
        else{
            this.setState({
                endDate: callbackData,
            });
        }
    };

    handleSubmit = event => {
        event.preventDefault();
        if (this.isAPS()) {
            if (!this.state.endDate || !this.state.startDate) {
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                });
                this.showDialog('Please fix the highlighted errors to continue', 'Error', Dialogs.INFO);
                this.setState({
                    isLoading: false,
                    showEndDateValidationError:true,
                    showStartDateValidationError:true
                });
                return;
            }
        }
        else if(this.isResourceAug()){

            if (!this.state.endDateAugmentation || !this.state.startDate) {
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                });
                this.showDialog('Please fix the highlighted errors to continue', 'Error', Dialogs.INFO);
                this.setState({
                    isLoading: false,
                    showEndDateValidationError:true,
                });
                return;
            }
        }
        else if(this.isBlanketWork()){
            if (!this.state.endDateBlanketWork || !this.state.startDate) {
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                });
                this.showDialog('Please fix the highlighted errors to continue', 'Error', Dialogs.INFO);
                this.setState({
                    isLoading: false,
                    showEndDateValidationError:true,
                });
                return;
            }
        }
        this.updateChangeReq(event);
    };

    onClickCancel = event => {
        event.preventDefault();
        if (this.state.isFormDirty && !this.state.isViewMode) {
            this.toggleConfirmDialog(
                'This form contains unsaved data. Do you want to close it?',
                this.navigateBack
            );
        }
        else {
            this.setState({
                redirect: 'home'
            })
        }
    };

    navigateBack = () => {
        this.setState({
            redirect: 'home'
        });
    };

    updateChangeReq = () => {
        this.setState({
            isLoading: true,
            submitted: true,
            isErrorMessageVisible: true //Used for legacy BillingDetails Component
        });
        const otherCompValid = this.validator.validate(this.state);
        if (!this.state.changeReqDetailsValid || !otherCompValid.isValid) {
            this.setState({
                showSuccessImage: false,
                showErrorImage: true,
            });
            this.showDialog('Please fix the highlighted errors to continue', 'Error', Dialogs.INFO);
            this.setState({
                isLoading: false
            });
            return;
        }
        if(this.allocationValidation()){
            this.setState({
                showSuccessImage: false,
                showErrorImage: true,
            });
            this.showDialog('Please fill mandatory fields in resource mix to continue', 'Error', Dialogs.INFO);
            this.setState({
                isLoading: false
            });
            return;
        }

        if (this.milestonesValidation()){
            this.setState({
                showSuccessImage: false,
                showErrorImage: true,
            });
            this.showDialog('Please fill mandatory fields in milestones to continue', 'Error', Dialogs.INFO);
            this.setState({
                isLoading: false
            });
            return;           
        }

        let endDate = null

        if (this.isAPS()){
            endDate = this.state.endDate
        }
        else if(this.isResourceAug()){
            endDate = this.state.endDateAugmentation
        }
        else if(this.isBlanketWork()){
            endDate = this.state.endDateBlanketWork
        }

        let statusId = null;
        if (this.state.statusValue) {
            statusId = this.state.statusValue.id;
        } else {
            statusId = this.state.changeRequest ? this.state.changeRequest.StatusId : null;
        }

        let startDateOnProjectConversionToBillable = null;

        if(this.state.statusValues){
            if (this.state.statusValues.id === 5 || this.state.statusValues.id === 6) {
                startDateOnProjectConversionToBillable = this.state.startDate ? this.state.startDate : null;
                startDateOnProjectConversionToBillable = startDateOnProjectConversionToBillable ? moment(startDateOnProjectConversionToBillable).format('YYYY-MM-DD') : null
            }
        }


        const changeRequest = {
            ProjectId: this.state.projectId,
            prevStatus: this.state.prevStatus,
            BillableStatus: this.state.billablestatus,
            BillingDivisionId: this.state.billingdiviValue.id,
            BillingRegion: this.state.billingregionValue.id,
            ProjectTypeId: this.state.projtypeValue.id,
            PaymentMethodId: this.state.paymethodValue ? this.state.paymethodValue.id : null,
            SalesPersonId: this.state.salespersValue ? this.state.salespersValue.id : null,
            PartnerId: this.state.projpartnerValue ? this.state.projpartnerValue.id : null,
            CustomerId: this.state.cusnameValue.id,
            OPID: this.state.opid.trim(),
            ProgramId: this.state.prognameValue ? this.state.prognameValue.id : null,
            PresalesResourceId: null,
            CountryId: this.state.countryValue.id,
            OfferingId: this.state.offeringValue.id,
            UniqueId: this.state.changeReqId,
            EndDate: endDate,
            StartDate: this.state.startDate,
            ChangeRequestConversionStartDate: startDateOnProjectConversionToBillable,
            PracticeId: this.state.practiceValue ? this.state.practiceValue.id : null,
            PONumber: this.state.poNumber ? this.state.poNumber.trim() : null,
            ContractedEffort: this.state.contractedEffort ? Number(this.state.contractedEffort).toFixed(2) : null,
            BillingEntityId: this.state.billingEntityValue ? this.state.billingEntityValue.id : null
            };

        Object.assign(changeRequest, this.state.changeReqDetails);

        if (this.isAPS()) {
            changeRequest.StartDate = this.state.startDate
            changeRequest.EndDate = this.state.endDate
        }
        else if(this.isResourceAug()){
            changeRequest.StartDate = this.state.startDate
            changeRequest.EndDate = this.state.endDateAugmentation
        }
        else if(this.isBlanketWork()){
            changeRequest.StartDate = this.state.startDate
            changeRequest.EndDate = this.state.endDateBlanketWork
        } else {
            changeRequest.StartDate = this.state.startDate
        }

        if (changeRequest.StartDate) {
            changeRequest.StartDate = changeRequest.StartDate.toDateString();
        }

        if ((this.isAPS() && changeRequest.EndDate) || (this.isResourceAug() && changeRequest.EndDate) || (this.isBlanketWork() && changeRequest.EndDate)) {
            changeRequest.EndDate = changeRequest.EndDate.toDateString();
        }

        // BILLABLE OR WORK_AT_RISK
        if (
            this.state.changeRequest &&
            !changeRequest.StartDate &&
            (statusId &&
                (statusId === 5 ||
                    statusId === 6))
        ) {
            this.setState({
                showStartDateValidationError: true
            });
            if (!changeRequest.StartDate) {
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                });
                this.showDialog('The CR start date cannot be empty', 'Error', Dialogs.INFO);
                this.setState({
                    isDisableSave: false,
                    isLoading: false
                });
                return;
            } else if (changeRequest.StartDate && new Date(changeRequest.StartDate) < new Date()) {
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                });
                this.showDialog('The CR start date cannot be a past date!', 'Error', Dialogs.INFO);
                this.setState({
                    isDisableSave: false,
                    isLoading: false
                });
                return;
            }
        }


        if (this.state.changeRequestId) { //Update
            if(this.submitForApproval()){
                this.showDialog('Invalid line selection', 'Error', Dialogs.INFO);
                this.setState({
                    isLoading: false
                });
                return;
            }

            else {

                updateChangeReq(changeRequest, this.state.changeRequestId)
              .then(async res => {
                  var allocationsToSave = [];
                  let allocPendingLines = [];
                  for(const eachAllocation of this.state.preSalesResourceAllocations){
                      const AllocationObj = {
                          id : eachAllocation.id,
                          CMSheetId: res.data.CMSheets[0] ? res.data.CMSheets[0].id : null,
                          Type: 'ChangeRequest',
                          ProjectId: res.data.id,
                          Status: eachAllocation.selected === true ? 'RES_ALLOC_PENDING' : eachAllocation.Status,
                          Comment: (eachAllocation.selected === true && this.state.allocComment) ? this.state.allocComment.replace(/(<([^>]+)>)/ig, '') : (eachAllocation.Comment ? eachAllocation.Comment : null),
                          CMSheetVersion: res.data.CMSheets[0] ? res.data.CMSheets[0].Version : null,
                          Designation: eachAllocation.Designation,
                          ResourceRegion: eachAllocation.ResourceRegion,
                          EstimatedEndDate: eachAllocation.EstimatedEndDate ? eachAllocation.EstimatedEndDate.toDateString() : null,
                          StartDate: eachAllocation.StartDate ? eachAllocation.StartDate.toDateString() : null,
                          EndDate: eachAllocation.EndDate ? eachAllocation.EndDate.toDateString() : null,
                          AllocationPercentage: eachAllocation.AllocationPercentage,
                          EffortDays: eachAllocation.EffortDays,
                          TotalEffortDays: eachAllocation.TotalEffortDays,
                          IsPreSales: eachAllocation.IsPreSales,
                          IsActive: eachAllocation.IsActive,
                          ProjectName: res.data.CRName,
                          UniqueId: res.data.UniqueId,
                          selected: eachAllocation.selected,
                          CostPerResource: Number(eachAllocation.CostPerResource),
                          DirectCost: Number(eachAllocation.DirectCost),
                          TotalCost: Number(eachAllocation.TotalCost),
                          ManagerComment: eachAllocation.ManagerComment,
                          OnsiteStatus: eachAllocation.OnsiteStatus
                      };
                      allocationsToSave.push(AllocationObj);
                      if (eachAllocation.selected === true) {
                        allocPendingLines.push(AllocationObj);
                      }
                  }
                  await saveResourceRequests(allocationsToSave).
                  then(async saveRes => {
                    if (allocPendingLines.length > 0) {
                        await savePresalesResourceLineHistory(allocPendingLines)
                    }
                    // Save Milestones
                    if (this.state.paymethodValue.id !== 2 && this.state.milestones && this.state.milestones.length > 0) {
                        for(const milestone of this.state.milestones){
                        milestone.ProjectId = res.data.ProjectId;
                        milestone.CrId = res.data.id;
                        milestone.StartDate = moment(new Date(milestone.StartDate)).format('YYYY-MM-DD');
                        milestone.EndDate = moment(new Date(milestone.EndDate)).format('YYYY-MM-DD');
                        milestone.Revenue = Number(Number(milestone.Revenue).toFixed(2));
                        milestone.IsActive = true;
                        milestone.FileId = milestone.FileId ? milestone.FileId : null
                        }
                        await saveMilestones(this.state.milestones).then(saveRes => {
                        }).catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                            this.showDialog(error.response.data, 'Error',Dialogs.INFO);
                        });  
                    }
                      this.setState({
                          isLoading: false,
                          showSuccessImage: true,
                          showErrorImage: false,
                          triggerRedirect: 'home'
                      });
                      this.showDialog(`The CR is successfully updated`, 'Success', Dialogs.INFO);
                  })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        this.showDialog(error.response.data, 'Error',Dialogs.INFO);
                        this.setState({
                            isLoading: false
                        });
                    });
              })
              .catch(error => {
                  loggerService.writeLog(error, LOG_TYPES.ERROR);
                  this.showDialog(error.response.data, 'Error', Dialogs.INFO);
                  this.setState({
                      isDisableSave: false,
                      isLoading: false
                  });
              });}
        } else { // Create
            //check for manager select
            let managerSelected = false;
            for(const allocation of this.state.preSalesResourceAllocations){
                if(allocation.dataValueCostDesignation.Name.toLowerCase().includes("manager") && allocation.selected === true){
                    managerSelected = true;
                }
            }
            if(managerSelected === true){

                if (!this.state.billablestatus && !this.state.showNbWarning) {
                    this.setState({
                        isLoading: false,
                      showNbWarning: true
                    });
                    this.toggleWarningDialog('The Billing type of the Project/CR is selected as Non-billable. Please proceed with Project Creation if this is intended.', 'Note');
                } else {
                    saveChangeReq(changeRequest)
                    .then(async res => {
                        var allocationsToSave = [];
                        let allocPendingLines = [];
                        for(const eachAllocation of this.state.preSalesResourceAllocations){
                            const AllocationObj = {
                                CMSheetId: res.data.CMSheets ? res.data.CMSheets[0].id : null,
                                Type: 'ChangeRequest',
                                ProjectId: res.data.id,
                                Status: eachAllocation.selected === true ? 'RES_ALLOC_PENDING' : eachAllocation.Status,
                                Comment: (eachAllocation.selected === true && this.state.allocComment) ? this.state.allocComment.replace(/(<([^>]+)>)/ig, '') : (eachAllocation.Comment ? eachAllocation.Comment : null),
                                CMSheetVersion: res.data.CMSheets ? res.data.CMSheets[0].Version : null,
                                Designation: eachAllocation.Designation,
                                ResourceRegion: eachAllocation.ResourceRegion,
                                EstimatedEndDate: eachAllocation.EstimatedEndDate ? eachAllocation.EstimatedEndDate.toDateString() : null,
                                StartDate: eachAllocation.StartDate ? eachAllocation.StartDate.toDateString() : null,
                                EndDate: eachAllocation.EndDate ? eachAllocation.EndDate.toDateString() : null,
                                AllocationPercentage: eachAllocation.AllocationPercentage,
                                EffortDays: eachAllocation.EffortDays,
                                TotalEffortDays: eachAllocation.TotalEffortDays,
                                IsPreSales: eachAllocation.IsPreSales,
                                IsActive: eachAllocation.IsActive,
                                ProjectName: res.data.CRName,
                                UniqueId: res.data.UniqueId,
                                selected: eachAllocation.selected,
                                CostPerResource: eachAllocation.CostPerResource,
                                DirectCost: eachAllocation.DirectCost,
                                TotalCost: eachAllocation.TotalCost,
                                ManagerComment: eachAllocation.ManagerComment,
                                OnsiteStatus: eachAllocation.OnsiteStatus
                            };
                            allocationsToSave.push(AllocationObj);
                            if (eachAllocation.selected === true) {
                                allocPendingLines.push(AllocationObj);
                            }
                        }
                        await saveResourceRequests(allocationsToSave)
                            .then(
                            async saveRes => {
                                if (allocPendingLines.length > 0) {
                                    await savePresalesResourceLineHistory(allocPendingLines)                                
                                }
                                // Save Milestones
                                if (this.state.paymethodValue.id !== 2 && this.state.milestones && this.state.milestones.length > 0) {
                                    for(const milestone of this.state.milestones){
                                    milestone.ProjectId = res.data.ProjectId;
                                    milestone.CrId = res.data.id;
                                    milestone.StartDate = moment(new Date(milestone.StartDate)).format('YYYY-MM-DD');
                                    milestone.EndDate = moment(new Date(milestone.EndDate)).format('YYYY-MM-DD');
                                    milestone.Revenue = Number(Number(milestone.Revenue).toFixed(2));
                                    milestone.IsActive = true;
                                    milestone.FileId = milestone.FileId ? milestone.FileId : null
                                    }
                                    await saveMilestones(this.state.milestones).then(saveRes => {
                                    }).catch(error => {
                                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                                        this.toggleDialog(error.response.data, 'Error');
                                    });  
                                }
                                this.setState({
                                    isLoading: false,
                                    showSuccessImage: true,
                                    showErrorImage: false,
                                    triggerRedirect: 'home'
                                });
                                this.showDialog(`The CR ${res.data.UniqueId} is successfully created`, 'Success', Dialogs.INFO);

                            }
                            ).catch(error => {
                                loggerService.writeLog(error, LOG_TYPES.ERROR);
                                this.showDialog(error.response.data, 'Error',Dialogs.INFO);
                                this.setState({
                                    isLoading: false
                                });
                            });
                        })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        this.showDialog(error.response.data, 'Error', Dialogs.INFO);
                        this.setState({
                            isDisableSave: false,
                            isLoading: false
                        });
                    });
                }
            }
            else{
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                });
                this.showDialog('At least one PM resource request should be selected to continue', 'Error',Dialogs.INFO);
                this.setState({
                    isDisableSave: false,
                    isLoading: false
                });
            }
        }
    };

    allocationValidation = () => {
        for(const eachAllocation of this.state.preSalesResourceAllocations){
            if (eachAllocation.Designation === null || eachAllocation.dataValueResourceRegion === null || eachAllocation.StartDate === null || eachAllocation.EndDate === null || eachAllocation.AllocationPercentage === null || eachAllocation.AllocationPercentage === 0 || !eachAllocation.OnsiteStatus){
                return true;
                break;
            }
        }
    }

    milestonesValidation = () => {
        if (this.state.milestones && this.state.milestones.length > 0) {
            for(const milestone of this.state.milestones){
            if (!milestone.Name || milestone.StartDate === null ||  milestone.EndDate === null || milestone.Revenue === null){
                return true;
            }
            }  
        } else {
            return false;
        }
    }

    handleUpdateMilestones = (milestones) => {
        this.setState({
            milestones: milestones
        });
    };

    handleSelect = (e) => {
        this.setState({ selected: e.selected });
    };

    submitForApproval = () => {
        const selectedResources = this.state.preSalesResourceAllocations.filter(resource => resource.selected === true);
        if (selectedResources && selectedResources.length > 0) {
            if(this.state.statusValue.StatusName !== 'Presales'){
                return true;
            }
            for(const resource of selectedResources){
                if(resource.Status !== 'NOT_SUBMITTED'){
                    return true;
                    break;
                }
            }
        }
    }

    handleBillableOnChange = async event => {
        const value = event.target.value;
        const field = event.target.name;

        switch (field) {
            case 'billale': {
                if (value === 'billable') {
                    this.setState({
                        billablestatus: true
                    });
                } else {
                    this.setState({
                        billablestatus: false
                    });
                }
                break;
            }
            case 'salesPerson': {
                this.setState({
                    salespersValue: value,
                    isFormDirty: true
                });
                break;
            }
            case 'partner': {
                this.setState({
                    projpartnerValue: value,
                    isFormDirty: true
                });
                break;
            }
            case 'billingdivi': {
                await this.setState({
                    billingdiviValue: value,
                    isFormDirty: true
                });
                if (value && this.state.projtypeValue) {
                    this.setState({
                        showEndDateValidationError: false
                    });
                    if (this.state.projtypeValue.Name === "Application Support" && value.id === 30) {
                        this.state.permissions.endDate = true;
                        this.state.showEndDateValidationError = true;
                        this.state.showStartDateValidationError = true;
                    }
                    else {
                        this.state.permissions.endDate = false;
                    }
                } else {
                    this.state.permissions.endDate = false;
                }
                if (event && event.target && event.target.value) {
                    this.practiceDivisionMapping();
                    await this.setState({ 
                      practiceValue: null
                    });
                } else {
                    await this.setState({ 
                        practiceValue: null
                    });
                }
                break;
            }
            case 'billingregion': {
                if (value) {
                    this.populateCountries(value.Country);
                    this.countryAll = value.Country;
                } else {
                    this.populateCountries([]);
                    this.countryAll = [];
                }

                this.setState({
                    billingregionValue: value,
                    countryValue: null
                });
                break;
            }
            case 'country': {
                this.setState({
                    countryValue: value,
                    isFormDirty: true
                });
                break;
            }
            case 'selectedPractice': {
                this.setState({
                  practiceValue: value
                });
                break;
              }
            default: {
            }
        }
        this.setState({
            isFormDirty: true
        });
    };

    practiceDivisionMapping = async () => {
        if (this.state.billingdiviValue) {
            let practices = [];
            let practicesForDivision = this.state.practiceDivisionMap.filter(item => item.DepartmentCodeSF === this.state.billingdiviValue.SFCode);
            for (const practice of practicesForDivision) {
                let selectedPractice = this.allPracticeTypes.filter(item => item.SFCode === practice.PracticeCodeSF);
                practices.push(selectedPractice[0]);
            }
            await this.setState({ practiceItems: practices })
        }
    };
    
    handleBillableFilterChange = event => {
        const value = event.target.value;
        const field = event.target.name;

        switch (field) {
            case 'salesPerson': {
                this.setState({
                    salespersonItems: this.filterSalespersonData(event.filter)
                });
                break;
            }
            case 'partner': {
                this.setState({
                    projparnterItems: this.filterProjectPartnersData(event.filter)
                });
                break;
            }
            case 'partners': {
                this.setState({
                    projparnterItems: this.filterProjectPartnersData(event.filter)
                });
                break;
            }
            case 'billingdivi': {
                this.setState({
                    billingdiviItems: this.filterBillingDivData(event.filter)
                });
                break;
            }
            case 'billingregion': {
                this.setState({
                    billingregionItems: this.filterBillingRegionData(event.filter)
                });
                break;
            }
            case 'country': {
                this.setState({
                    countryItems: this.filterDataCountries(event.filter)
                });
                break;
            }
            case 'selectedPractice': {
                this.setState({
                  practiceItems: this.filterPracticeData(event.filter)
                });
                break;
            }
        }

        this.setState({
            isFormDirty: true
        });
    };

    //filterevents for the combo boxes
    filterData(filter) {
        const data = this.customerAll.slice();
        return filterBy(data, filter);
    }

    filterChangeResource = event => {
        this.setState({
            resourceItems: this.filterDataResource(event.filter)
        });
    };
    filterDataResource(filter) {
        const data = this.resourceAll.slice();
        return filterBy(data, filter);
    }

    filterCountries = event => {
        this.setState({
            countryItems: this.filterDataCountries(event.filter)
        });
    };
    filterDataCountries(filter) {
        const data = this.countryAll.slice();
        return filterBy(data, filter);
    }

    filterBillingDivData(filter) {
        const data = this.allBillingDivisions.slice();
        return filterBy(data, filter);
    }

    filterPracticeData(filter) {
        const data = this.allPracticeTypes.slice();
        return filterBy(data, filter);
      }

    filterEngagement = event => {
        this.setState({
            projectTypeItems: this.filterEngagementData(event.filter)
        });
    };
    filterEngagementData(filter) {
        const data = this.allEngagementTypes.slice();
        return filterBy(data, filter);
    }
    filterSalespersonData(filter) {
        const data = this.allSalesPersons.slice();
        return filterBy(data, filter);
    }

    filterBillingRegionData(filter) {
        const data = this.allBillingRegions.slice();
        return filterBy(data, filter);
    }
    filterBillingMethod = event => {
        this.setState({
            paymentmethodItems: this.filterBillingMethodData(event.filter)
        });
    };
    filterBillingMethodData(filter) {
        const data = this.allBillingMethods.slice();
        return filterBy(data, filter);
    }

    filterProjectPartnersData(filter) {
        const data = this.allProjectPartners.slice();
        return filterBy(data, filter);
    }

    filterProgramData(filter) {
        const data = this.allPrograms.slice();
        return filterBy(data, filter);
    }

    filterOfferings = event => {
        this.setState({
            offeringItems: this.filterOfferingData(event.filter)
        });
    };
    filterOfferingData(filter) {
        const data = this.allOfferings.slice();
        return filterBy(data, filter);
    }

    filterBillingEntities = event => {
        this.setState({
          billingEntityType: this.filterBillingEntityData(event.filter)
        });
    };
    filterBillingEntityData(filter) {
        const data = this.allBillingEntities.slice();
        return filterBy(data, filter);
    }    

    handleProjTypeChange = event => {
        let projtypeValue = event.target.value;
        this.setState({
            projtypeValue: projtypeValue,
            isFormDirty: true
        });

        //for resource augmentation
        if(projtypeValue && projtypeValue.Name === 'Resource Augmentation'){
            this.state.permissions.endDateAugmentation = true;
            this.setState({
                resourceAug:true,
                showStartDateValidationError: false,
                endDateAugmentation:null
            });
        }
        else{
            this.state.permissions.endDateAugmentation = false;
        }

        //for blanket work order projects
        if(projtypeValue && projtypeValue.Name === 'Blanket Work Order'){
            this.state.permissions.endDateBlanketWork = true;
            this.setState({
                isBlanketWorkOrder:true,
                showStartDateValidationError: false,
                endDateBlanketWork:null
            });
        }
        else{
            this.state.permissions.endDateBlanketWork = false;
        }


        if (projtypeValue && this.state.billingdiviValue) {
            this.setState({
                showStartDateValidationError: false
            });
            if (projtypeValue.Name === "Application Support" && this.state.billingdiviValue.id === 30) {
                this.state.permissions.endDate = true;
            }
            else {
                this.state.permissions.endDate = false;
            }

        } else {
            this.state.permissions.endDate = false;
        }



        const CRData = JSON.parse(JSON.stringify(this.state.changeRequest));
        CRData.BillingDivision = this.state.billingdiviValue;
        CRData.Type = projtypeValue;

        this.setState({
            changeRequest : CRData
        });
    };

    handlePaymentChange = event => {
        this.setState({
            paymethodValue: event.target.value,
            isFormDirty: true
        });
    };

    handleOfferingChange = event => {
        const value = event.target.value;
        this.setState({
            offeringValue: value,
            isFormDirty: true
        });
    };

    handleBillingEntityChange = event => {
        const value = event.target.value;
        this.setState({
          billingEntityValue: value,
          isFormDirty: true
        });
    };

    handleResourceChange = event => {
        this.setState({
            resourceValue: event.target.value,
            isFormDirty: true
        });
    };

    isAPS = () => {
        if (this.state.projtypeValue && this.state.billingdiviValue && this.state.projtypeValue.Name === "Application Support" && this.state.billingdiviValue.id === 30) {
            return true
        } else {
            return false
        }
    }

    isResourceAug = () => {
        if (this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation") {
            return true
        } else {
            return false
        }
    }

    onStatusTab = callbackData => {
        this.setState({
            statusValues: callbackData.statusValues,
        });
    };

    isBlanketWork = () => {
        if (this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order") {
            return true
        } else {
            return false
        }
    }

    handleUpdateProj = (resAllocations, allocComment) => {
        this.setState({
            preSalesResourceAllocations: resAllocations,
            allocComment : allocComment
        });
    };

    render() {
        if (this.state.redirect === 'projectSearch') {
            return <Redirect to="/projects/search/search" />;
        } else if (this.state.redirect === 'home') {
            return <Redirect to="/" />;
        }
        if (this.state.toDashboard === true) {
            return <Redirect to="/" />;
        }

        let validation =
            this.state.submitted === true // if show validations property is true
                ? this.validator.validate(this.state) // then check validity every time we render
                : this.state.validation;

        return (
            <div className="main-card">
                <Loader loading={this.state.isLoading} />
                <CustomerDetails
                    customerItems={this.state.customerItems}
                    customerValue={this.state.cusnameValue}
                    programItems={this.state.programItems}
                    programValue={this.state.prognameValue}
                    opidValue={this.state.opid}
                    handleFilterChange={this.handleFilterChange}
                    handleOnChange={this.handleInputChange}
                    viewMode={this.state.isViewMode}
                    action={this.state.action}
                    projectIdValue={this.state.changeReqId}
                    permissions={this.state.permissions}
                    errorMessageVisible={this.state.isErrorMessageVisible}
                    editable={this.state.editable}
                    UUIDName={'CR ID'}
                    allowOpidEdit={this.state.allowOpidEdit}
                    oldOpIdHistory={this.state.oldOpIdHistory}
                    onClickOpidHistory={this.onClickOpidHistory}          
                    isStatusOnHold={this.state.onHoldStatusBool}
                    poNumber={this.state.poNumber}
                    contractedEffort={this.state.contractedEffort}
                    billingDivisionValue={this.state.billingdiviValue}
                ></CustomerDetails>

                <div className="main-seperator" />

                {this.state.changeRequest && (
                    <EditCRDetails
                        changeReqDetails={this.state.changeRequest}
                        billingDiviValue={this.state.billingdiviValue}
                        projTypeValue={this.state.projtypeValue}
                        onDetailsChange={callbackData => this.onCRDetailsChange(callbackData)}
                        onHandleStartDate={this.onHandleStartDate}
                        onHandleEndDate={this.onHandleEndDate}
                        showValidations={this.state.submitted}
                        action={this.state.action}
                        viewMode={this.state.isViewMode}
                        permissions={this.state.permissions}
                        errorMessageVisible={this.state.isErrorMessageVisible}
                        showStartDateValidationError={this.state.showStartDateValidationError}
                        showEndDateValidationError={this.state.showEndDateValidationError}
                        statusValue={this.state.statusValue}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        estimatedEndDate={this.state.changeRequest.EstimatedEndDate}
                        endDateAugmentation={this.state.endDateAugmentation}
                        endDateBlanketWork={this.state.endDateBlanketWork}
                        isAPS={this.isAPS()}
                        isResourceAug = {this.isResourceAug()}
                        isBlanketWork = {this.isBlanketWork()}
                        isStatusOnHold={this.state.onHoldStatusBool}
                        onStatusChange={callbackData => this.onStatusTab(callbackData)}
                    ></EditCRDetails>
                )}

                <div className="main-seperator" />

                <BillingDetails
                    billableStatus={this.state.billablestatus}
                    salesPersonItems={this.state.salespersonItems}
                    salesPersonValue={this.state.salespersValue}
                    projParnterItems={this.state.projparnterItems}
                    projPartnerValue={this.state.projpartnerValue}
                    billingDiviItems={this.state.billingdiviItems}
                    billingDiviValue={this.state.billingdiviValue}
                    practiceItems={this.state.practiceItems}
                    practiceValue={this.state.practiceValue}
                    billingRegionItems={this.state.billingregionItems}
                    billingRegionValue={this.state.billingregionValue}
                    countryItems={this.state.countryItems}
                    countryValue={this.state.countryValue}
                    projectTypeItems={this.state.projectTypeItems}
                    projTypeValue={this.state.projtypeValue}
                    handleBillableOnChange={this.handleBillableOnChange}
                    handleBillableFilterChange={this.handleBillableFilterChange}
                    permissions={this.state.permissions}
                    viewMode={this.state.isViewMode}
                    errorMessageVisible={this.state.isErrorMessageVisible}
                    editable={this.state.editBillingDetails}
                    labels={{ countryLabel: 'CR Country', partnerLabel: 'Partner' }}
                    action={this.state.action}
                ></BillingDetails>

                <div className="row margin-top-10">
                    <div className="col-md-2">
                        <div className="">
                            <div className="d-block">
                                <label htmlFor="" className="mandatory">
                                    Engagement Type:
                              </label>
                              </div>
                            <div className="d-block" id="engagementToolTip">
                                <ComboBox
                                    data={this.state.projectTypeItems}
                                    textField="Name"
                                    dataItemKey="id"
                                    placeholder="Please Select"
                                    value={this.state.projtypeValue}
                                    onChange={this.handleProjTypeChange}
                                    required={true}
                                    disabled={this.state.isViewMode || this.state.action === 'edit'}
                                    filterable={true}
                                    onFilterChange={this.filterEngagement}
                                />
                                <span className="inline-error">{validation.projtypeValue.message}</span>
                            </div>
                            {this.state.projtypeValue ? (
                                <UncontrolledTooltip placement="top" target="engagementToolTip">
                                    {this.state.projtypeValue.Name}
                                </UncontrolledTooltip>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="">
                            <div className="d-block">
                                <label htmlFor="" className="mandatory">
                                    {' '}
                                    Billing Method:
                              </label>
                            </div>
                            <div className="d-block" id="billmethodToolTip">
                                <ComboBox
                                    data={this.state.paymentmethodItems}
                                    textField="Name"
                                    dataItemKey="id"
                                    placeholder="Please Select"
                                    value={this.state.paymethodValue}
                                    onChange={this.handlePaymentChange}
                                    required={true}
                                    disabled={this.state.isViewMode || !this.state.permissions.billingMethod || this.state.billingMethod}
                                    filterable={true}
                                    onFilterChange={this.filterBillingMethod}
                                />
                                <span className="inline-error">{validation.paymethodValue.message}</span>
                            </div>
                            {this.state.paymethodValue ? (
                                <UncontrolledTooltip placement="top" target="billmethodToolTip">
                                    {this.state.paymethodValue.Name}
                                </UncontrolledTooltip>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="">
                            <div className="d-block">
                                <label htmlFor="" className="mandatory">
                                    Offering:
                              </label>
                            </div>
                            <div className="d-block" id="offeringToolTip">
                                <ComboBox
                                    data={this.state.offeringItems}
                                    textField="Name"
                                    dataItemKey="id"
                                    placeholder="Please Select"
                                    value={this.state.offeringValue}
                                    onChange={this.handleOfferingChange}
                                    required={true}
                                    disabled={this.state.isViewMode || !this.state.permissions.offering || this.state.offering}
                                    filterable={true}
                                    onFilterChange={this.filterOfferings}
                                />
                                <span className="inline-error">{validation.offeringValue.message}</span>
                            </div>
                            {this.state.offeringValue ? (
                                <UncontrolledTooltip placement="top" target="offeringToolTip">
                                    {this.state.offeringValue.Name}
                                </UncontrolledTooltip>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="">
                            <div className="d-block">
                                <label htmlFor="" className="">
                                    Billing Entity:
                              </label>
                            </div>
                            <div className="d-block" id="billingEntityToolTip">
                                <ComboBox
                                    data={this.state.billingEntityType}
                                    textField="Name"
                                    dataItemKey="id"
                                    placeholder="Please Select"
                                    value={this.state.billingEntityValue}
                                    onChange={this.handleBillingEntityChange}
                                    disabled={this.state.isViewMode}
                                    filterable={true}
                                    onFilterChange={this.filterBillingEntities}
                                />
                            </div>
                            {this.state.billingEntityValue ? (
                                <UncontrolledTooltip placement="top" target="offeringToolTip">
                                    {this.state.billingEntityValue.Name}
                                </UncontrolledTooltip>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="main-seperator" />

            <TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
                <TabStripTab title="Pre-sales Resource Request">
                <ResourceRequest
                    resourceItems={this.state.resourceItems}
                    resourceValue={this.state.resourceValue}
                    handleResourceChange={this.handleResourceChange}
                    filterChangeResource={this.filterChangeResource}
                    viewMode={this.state.isViewMode}
                    statusValue={this.state.statusValue}
                    countryValue={this.state.countryValue}
                    billingregionValue={this.state.billingregionValue}
                    isAPS={this.state.isAPS}
                    action={this.state.action}
                    project={this.state.changeRequest ? this.state.changeRequest : this.state.project}
                    startdate={this.state.startdate}
                    onUpdateProj={this.handleUpdateProj}
                    preSalesResourceAllocations={this.state.preSalesResourceAllocations}
                    Type={'ChangeRequest'}
                    errorMessageVisible={this.state.isErrorMessageVisible}
                ></ResourceRequest>
                 </TabStripTab>
                <TabStripTab title="Milestones" 
                    disabled={(this.state.paymethodValue == null || this.state.paymethodValue.id === 2) ? true : false }
                >
                    <Milestones
                        viewMode={this.state.isViewMode}
                        isEditMode={this.state.isEditMode}
                        action={this.state.action}
                        project={this.state.changeRequest ? this.state.changeRequest : this.state.project}
                        Type={'Project'}
                        onUpdateMilestones={this.handleUpdateMilestones} 
                        milestones={this.state.milestones}
                    >
                    </Milestones>
                </TabStripTab>
            </TabStrip>

                <div className="row mt-3">
                    <div className="col-md-12 btn-align-right">
                        <Button
                            type="submit"
                            primary={true}
                            disabled={this.state.isViewMode || this.state.isDisableSave}
                            onClick={this.handleSubmit}
                        >
                            Save
                      </Button>
                        <Button type="button" onClick={this.onClickCancel}>
                            Cancel
                      </Button>
                    </div>
                </div>

                {this.state.dialog.infoDialogVisible === true && (
                    <InfoDialog
                        dialogTitle={this.state.dialog.dialogTitle}
                        dialogMessage={this.state.dialog.dialogMessage}
                        showSuccessImage={this.state.showSuccessImage}
                        showErrorImage={this.state.showErrorImage}
                        dismissCallback={() => {
                            if (this.state.triggerRedirect) {
                                this.setState({
                                    redirect: this.state.triggerRedirect
                                });
                            } else {
                                this.setState({
                                    dialog: { ...this.state.dialog, infoDialogVisible: false }
                                });
                            }
                        }}
                    />
                )}

                {this.state.dialog.confirmationDialogVisible === true && (
                    <ConfirmationDialog
                        dialogTitle={this.state.dialog.dialogTitle}
                        dialogMessage={this.state.dialog.dialogMessage}
                        abortCallback={() => {
                            this.setState({
                                dialog: { ...this.state.dialog, confirmationDialogVisible: false }
                            });
                        }}
                        confirmCallback={this.state.dialog.callback}
                    />
                )}
                {this.state.showConfirmDialog === true && (
                    <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleConfirmDialog}>
                                No
                        </button>
                            <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                                Yes
                        </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
                {this.state.showMessageDialog === true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleWarningDialog} width="300px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                        <DialogActionsBar>
                        <button
                            className="k-button modal-primary"
                            onClick={this.toggleWarningDialog}
                        >
                            OK
                        </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
                {this.state.visibleOpidHistory && (
                    <Dialog width={600} title={"OPID Change History"} onClose={this.onClickOpidHistory}>
                        <div className="row">
                            <div className="col-md-12">
                                <Grid
                                data={this.state.oldOpIdHistory}
                                style={{ height: '100px' }}
                                >
                                <Column field="OPID" title="OPID" width="150px"/>
                                <Column field="UpdatedUser" title="User" width="150px"/>
                                <Column field="createdAt" title="Updated Date" />
                                </Grid>
                            </div>
                        </div>
                        <DialogActionsBar>
                            <button className="k-button modal-primary" onClick={this.onClickOpidHistory}>Close</button>
                        </DialogActionsBar>
                    </Dialog>
                )}
            </div>
        );
    }
}

export default EditChangeRequest;
